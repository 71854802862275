body {
	font-family: 'Roboto', sans-serif !important;
}

.form-select-col-2 {
	padding-bottom: 10px;

	>div[class*="css-"] {
		width: 100%;
		padding-right: 10px;
	}
}

.block-info-quick-support {
	h4 {
		margin: 20px 0;
	}
}

.fix-modal-height {
	height: 600px;
}

.input-has-border input {
	border: 1px solid #d8d6de !important;
}

.invalid-feedback2 {
	width: 100%;
	font-size: 1rem;
	color: #ea5455;
	padding-left: 5px;
}

.invalid-feedback2 {
	&.dis-inline {
		display: inline;
	}
}

[dir] .btn-primary {
	border-color: #2d58af !important;
	background-color: #2d58af !important;
}

[dir=ltr] .main-menu.menu-light .navigation>li ul .active,
[dir=ltr] .main-menu.menu-light .navigation>li.active>a {
	background: #2d58af;
}

html .table>tbody {
	// background: #fff;
}

.filter-listing {
	margin-bottom: 30px;
}

.filter-listing button {
	margin-right: 15px;
}

#qrcode {
	display: none;
	/* Showed when qr code received */
	width: 450px;
	margin: 10px 0;
	border: 1px solid #efefef;
	border-radius: 4px;
}

ul.logs {
	max-height: 150px;
	padding: 15px 15px 15px 30px;
	margin-top: 5px;
	border-radius: 4px;
	overflow-y: auto;
	background: #efefef;
	color: #666;
	font-size: 14px;
}

ul.logs li:first-child {
	color: green;
}

.title-log {
	margin-top: 30px;
}

button.btn-reload {
	display: none;
}

.badge-role {
	font-size: 82%;
	padding: 0.2rem 0.4rem;
}

.chat-body .text-secondary {
	float: right;
	margin: 0 1rem 10px 0;
}

.chat-left .chat-body .text-secondary {
	float: left;
	margin: 0 0 10px 1rem;
}

.btn-new-widget {
	display: block;
}

.chat-content .data-fslightbox,
.chat-content .video-fslightbox {
	max-width: 255px;
	cursor: pointer;
}

.chat-content .format-ifr {
	position: relative;
	cursor: pointer;
}

.chat-content .format-ifr span {
	width: 300px;
	height: 200px;
	background: #fff;
	opacity: 0.5;
	position: absolute;
	left: 0px;
}

.chat-content .format-ifr svg {
	width: 50px;
	height: 50px;
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.chat-application .content-wrapper {
	margin: 0px;
}

.chat-info .icon-media {
	padding-right: 5px;
}

.chat-app-window .chats .chat-body .chat-content div {
	max-width: 600px;
	white-space: pre-wrap;
}

.chat-app-window .chats .chat-body .chat-content-list .chat-content {
	position: relative;
	margin: 0 1rem 0px 15px !important;
}

.chat-app-window .chats .chat-body .chat-icon {
	right: -25px;
	top: 10px;
}

.load-spin {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100px;
}

#turn-tcn {
	display: none;
}

.chat-info .icon-chat {
	padding-left: 10px;
	position: relative;
	bottom: 6px;
}

.chat-info .icon-chat img {
	border: 0px !important;
}

.color-picker .css-1vpurlb-InputColor {
	border-radius: 50%;
}

.chat-users-list input:disabled {
	background-color: #fff;
	border: 1px dotted;
}

.chat-app-window .chat-content a {
	text-decoration: underline;
}

.chat-app-window .chats :not(.chat-left) .chat-body .chat-content a {
	color: #fff;
}

.chat-app-form ul.list-group {
	position: absolute;
	bottom: 35px;
}

.chat-app-form ul.list-group .btn-remove {
	display: block;
	margin-left: 10px;
}

.chat-left .not-avatar {
	margin-left: 36px !important;
}

.chat-users-list {
	max-height: 630px;
	overflow-y: scroll;
	// scrollbar-width: none;
	/* Firefox */
	// -ms-overflow-style: none;
	/* Internet Explorer 10+ */
}

.chat-users-list::-webkit-scrollbar {
	width: 8px;
	// background-color: transparent;
	/* Chrome/Safari/Webkit */
}

.chat-users-list::-webkit-scrollbar-track {
	background-color: #f5f5f5;
}

.chat-users-list::-webkit-scrollbar-thumb {
	background-color: #aaa;
	border-radius: 20px;
	border: 3px solid #aaa;
}

.chat-left .chat-body.not-avatar {
	margin-left: 35px !important;
}

.select__menu .select__menu-list .select__option:last-child {
	font-weight: 700;
	color: #7367f0;
}

.form-send-message {
	margin: 0px !important;
}

.chat-application .content-area-wrapper .fallback-spinner {
	display: none !important;
}

.input-group-merge .select__menu {
	min-width: 200px;
}

.header-wc {
	padding: 0 28px 0 108px;
	margin-top: -45px;
}

.vertical-overlay-menu .header-wc {
	padding: 0 28px;
}

.menu-expanded .header-wc {
	padding: 0 28px 0 288px;
}

.hide-left-menu .menu-expanded .header-wc {
	padding-left: 28px;
}

.chat-app-window .chat-app-form {
	padding: 0 0.3rem !important;
}

.chat-application .sidebar-content .chat-user-list-wrapper ul.nav-tabs {
	display: none;
}

.header-wc h2 {
	padding-right: 1rem;
	// border-right: 1px solid #d6dce1;
}

.header-wc .content-header-left {
	margin: 0px !important;
}

.wa-top-nav {
	position: relative;
	z-index: 252;
	display: flex; // flex
	justify-content: space-between;
	height: 48px;
	background-color: #f5f5f5;
	border: 1px solid #e5e5e6;
	top: 30px;
}

.dark-layout .wa-top-nav {
	background-color: #161D31 !important;
	border: 1px solid #3B4253;
	border-radius: 6px;
}

.nav-left-area {
	flex: 1;
	display: flex;
	align-items: center;
	width: calc(100% - 500px);
}

.nav-left-area .input-group {
	width: auto !important;
}

.menu-ext-chat.btn-group {
	margin: 0px !important;
}

.menu-ext-chat.btn-group button,
.chat-header button {
	border: 0px !important;
	padding: 0.3rem 1rem;
}

ul.group-list {
	list-style: none;
	margin: 0px;
}

ul.group-list li {
	display: flex;
	white-space: nowrap;
	margin: 0 15px;
	overflow-x: auto;
	overflow-y: hidden;
	cursor: pointer;
	float: left;
}

.nav-right-area {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-shrink: 0;
	-moz-column-gap: 10px;
	column-gap: 10px;
	padding-right: 24px;
	margin-left: 40px;
}

.profile-panel a.nav-link.active {
	background-color: transparent !important;
	color: #5e5873;
	box-shadow: unset !important;
	border: 0px;
	font-size: 1.286rem;
	font-weight: 500;
}

.form-template .dropzone {
	padding: 0.75rem 1.25rem;
	background-color: #fff;
	border: 1px solid rgba(34, 41, 47, 0.125);
}

.react-select-invalid {
	border: 1px solid red;
	border-radius: 5px;
}

.modal-dialog-chat .modal-title {
	font-size: 1.286rem;
}

.chat-icon-smile {
	cursor: pointer;
}

.chat-popup {
	position: absolute;
	bottom: 60px;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 10px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	z-index: 1;
	max-height: 150px;
	/* Chiều cao tối đa của popup */
	overflow-y: auto;
	/* Thêm thanh cuộn nếu cần */
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.chat-popup-emoji {
	font-size: 23px;
	margin: 5px;
	cursor: pointer;
}

.chat-message-emoji {
	font-size: 18px;
}

.typing-notification {
	display: flex;
	align-items: center;
	font-style: italic;
	color: #555;
	font-size: 16px;
	position: fixed;
	bottom: 115px;
}

.typing-notification span {
	margin-right: 5px;
}

.typing-dots div {
	width: 6px;
	height: 6px;
	margin-right: 3px;
	background-color: #555;
	border-radius: 50%;
	display: inline-block;
	/* Hiệu ứng nhấp nháy */
	animation: blink 1.4s infinite both;
}

.typing-dots div:nth-child(1) {
	animation-delay: 0s;
}

.typing-dots div:nth-child(2) {
	animation-delay: 0.2s;
}

.typing-dots div:nth-child(3) {
	animation-delay: 0.4s;
}

.card-body img#qrcode {
	width: 180px;
}

.group-list {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.group-list li {
	display: flex;
	align-items: center;
	padding: 10px;
	cursor: pointer;
}

.group-list li.active {
	background-color: #f0f0f0;
	font-weight: bold;
}

.group-list li svg {
	margin-right: 5px;
}

/* Keyframes cho hiệu ứng nhấp nháy */
@keyframes blink {

	0%,
	80%,
	100% {
		opacity: 0;
	}

	30%,
	50% {
		opacity: 1;
	}
}

.qr-loading-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 264px;
	height: 264px;
	background: #f5f5f5;
	border-radius: 8px;
	/* Giả sử bạn muốn bo tròn một chút */
	font-family: Arial, sans-serif;
	/* Chọn font chữ phù hợp */
	margin: 0 auto;
}

.qr-loading-spinner {
	width: 44px;
	height: 44px;
	border: 4px solid rgba(0, 0, 0, .1);
	border-radius: 50%;
	border-top-color: #000;
	animation: spin 1s infinite linear;
}

.qr-loading-text {
	margin-top: 12px;
	text-align: center;
	font-size: .875rem;
	color: #333;
	/* Màu chữ */
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@media (max-width: 1366px) {
	.chat-application .content-area-wrapper {
		height: 620px !important;
	}

	.chat-users-list {
		max-height: 480px;
	}
}

@media (max-width: 480px) {
	.chat-content img.data-fslightbox {
		max-width: 100%;
	}

	.chat-app-window .chats .chat-body .chat-content {
		overflow: hidden;
	}
}